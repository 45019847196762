import React from 'react'
import Image from '@components/Image'
import {
  websiteInitialData_cached_website,
  websiteInitialData_cached_website_navbarConfiguration
} from '@data/queries/website/__generated__/websiteInitialData_cached'
import Link from 'next/link'

import styles from './styles.module.css'

export interface LogoProps {
  config: websiteInitialData_cached_website_navbarConfiguration
  website: websiteInitialData_cached_website
  className?: string
}

export default function Logo(props: LogoProps) {
  const {website, config, className} = props
  const {logo} = config
  const url = logo?.url

  if (!url) return null

  return (
    <div className={styles.container}>
      <div className={className}>
        <Link href="/">
          <a>
            <div className={styles.imageContainer}>
              <Image
                priority
                layout="fill"
                src={url}
                objectFit="contain"
                alt={website.name || 'logo'}
                title={website.name || 'logo'}
              />
            </div>
          </a>
        </Link>
      </div>
    </div>
  )
}
