import React, {useEffect, useRef, useState} from 'react'
import {MdChevronRight as Chevron} from 'react-icons/md'
import scrollTo from '@helpers/misc/scrollTo'
import useWidth from '@hooks/useWidth'
import MobileCategoriesMenu from '@page-components/Order/CategoryPage/Content/TopRow/MobileCategoriesMenu'
import Link from 'next/link'
import {useRouter} from 'next/router'

import styles from './styles.module.css'

export default function NavbarLinks({
  links,
  categories,
  cpg
}: {
  links: any[]
  categories: any[]
  cpg?: boolean
}) {
  const router = useRouter()
  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const [categoriesMegaMenu, setCategoriesMegaMenu] = useState([])
  const [positionMenu, setPositionMenu] = useState({x: 0, y: 0, triangleX: 0})
  const [open, setOpen] = useState(false)
  const pageWidth = useWidth()
  const megamenu = useRef(null)
  const isMobile = pageWidth < 768

  let timer = null

  const handleMouseIn = (all, menu = false) => {
    if (timer) {
      clearTimeout(timer)
    }
    setShowMegaMenu(true)

    if (menu) {
      const targetposition = all.target.getBoundingClientRect()
      const {x, y, width} = targetposition
      const megamenuPosition = megamenu.current.getBoundingClientRect()
      const xPosition = (pageWidth - megamenuPosition.width) / 2
      setPositionMenu({
        x: xPosition,
        y: y,
        triangleX: x - xPosition + width / 3
      })
    }
  }

  const handleMouseOut = () => {
    timer = setTimeout(() => {
      setShowMegaMenu(false)
    }, 500)
  }

  const handleClick = () => {
    setShowMegaMenu(false)
  }

  const sortCategories = () => {
    const categoriesInfo = []
    categories.sort((a, b) => {
      if (a.parent && b.parent) {
        return a.index - b.index
      }
      if (a.parent) {
        return 1
      }
      if (b.parent) {
        return -1
      }
      return a.index - b.index
    })

    categories.forEach(category => {
      if (category.parent) {
        const parent = categoriesInfo.find(categoryMenu => categoryMenu._id === category.parent._id)
        if (parent) {
          parent.subcategories = parent.subcategories
            ? [...parent.subcategories, category]
            : [category]
        }
      } else {
        categoriesInfo.push(category)
      }
    })

    setCategoriesMegaMenu(categoriesInfo)
  }

  useEffect(() => {
    sortCategories()
    return () => {
      // cleanup function: clear timeout on unmount component
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  if (!links || !links.length) return null

  return (
    <div className={styles.container} style={{padding: cpg ? '5px 0' : '0 0 10px'}}>
      {links.map((link, index) => {
        if (isMobile && link.showMegaMenu) {
          return (
            <a
              key={index}
              className={styles.link}
              rel="noopener noreferrer"
              onClick={() => setOpen(true)}>
              {link.title}
            </a>
          )
        }
        if (!link.path.startsWith('/')) {
          return (
            <a
              href={link.path}
              target="_blank"
              key={index}
              className={styles.link}
              rel="noopener noreferrer">
              {link.title}
            </a>
          )
        }

        const active = link.path === router.asPath

        return (
          <Link href={isMobile && showMegaMenu ? '/' : link.path} passHref key={index}>
            <a
              onClick={() => {
                if (link.showMegaMenu && isMobile) {
                  setOpen(true)
                  return
                }
                if (!active) return
                const navbar = document.getElementsByClassName('mainNavbar')[0]
                const height = navbar.clientHeight
                scrollTo({element: navbar, top: height})
              }}
              className={active ? styles.active : styles.link}
              onMouseOver={event => {
                if (link.showMegaMenu && !isMobile) {
                  handleMouseIn(event, true)
                } else {
                  setShowMegaMenu(false)
                }
              }}
              onMouseOut={handleMouseOut}>
              {link.title}
            </a>
          </Link>
        )
      })}
      <div
        ref={megamenu}
        className={styles.megamenu}
        onMouseOver={handleMouseIn}
        onMouseOut={handleMouseOut}
        style={{
          top: positionMenu.y + 34,
          left: positionMenu.x,
          visibility: showMegaMenu ? 'visible' : 'hidden'
        }}>
        {categoriesMegaMenu.map(category => {
          return (
            <div key={category._id} className={styles.category}>
              <Link href={`/pedir/category/${category._id}`} passHref prefetch={false}>
                <a className={styles.categoryTitle} onClick={handleClick}>
                  {category.name}
                </a>
              </Link>
              {category.subcategories
                ? category.subcategories.map(subcategory => {
                    return (
                      <Link
                        key={subcategory._id}
                        href={`/pedir/category/${subcategory._id}`}
                        prefetch={false}
                        passHref>
                        <a className={styles.subcategoryTitle} onClick={handleClick}>
                          {subcategory.name}
                        </a>
                      </Link>
                    )
                  })
                : null}
              {category.subcategories ? (
                <Link href={`/pedir/category/${category._id}`} passHref prefetch={false}>
                  <a className={styles.seeAll} onClick={handleClick}>
                    Ver todos
                    <Chevron size={10} color="var(--primary-color)" />
                  </a>
                </Link>
              ) : null}
            </div>
          )
        })}
        <div className={styles.triangle} style={{top: -34, left: positionMenu.triangleX}}></div>
      </div>
      {open && <MobileCategoriesMenu setOpen={setOpen} />}
    </div>
  )
}
