import React from 'react'
import {MdStoreMallDirectory} from 'react-icons/md'

import styles from './styles.module.css'

export default function TableInfo(props) {
  const {store, table, Logo, color} = props
  return (
    <div className={styles.container}>
      <div className="flex-cols middle-xs">
        <Logo className={styles.logo} />
        <h4>Hola 👋🏻 Bienvenido a tu mesa</h4>
      </div>
      <div className={styles.data}>
        <div className={styles.store}>
          <MdStoreMallDirectory size="21" />
          <span className={styles.verticalBottom}> Local: {store}</span>
        </div>
        <div className={styles.store} style={{borderColor: color}}>
          <img src="/images/icons/table-2.svg" alt="Table Icon" /> Mesa: {table}
        </div>
      </div>
    </div>
  )
}
