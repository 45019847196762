import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import LoadingText from '@components/LoadingText'
import SafeSuspense from '@components/SafeSuspense'
import {websiteInitialData_cached_website_logo} from '@data/queries/website/__generated__/websiteInitialData_cached'
import isServerSide from '@helpers/misc/isServerSide'
import useHasUserRoleInWebsite from '@hooks/useHasUserRoleInWebsite'
import useSessionStorageState from '@hooks/useSessionStorageState'
import useWebsiteId from '@hooks/useWebsiteId'
import useInitialData from '@page-components/Layout/useInitialData'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import AdminBar from './AdminBar'
import Links from './Links'
import Logo from './Logo'
import Menu from './Menu'
import TableInfo from './TableInfo'

import styles from './styles.module.css'

export default function Navbar() {
  const websiteId = useWebsiteId()
  const {userPreferences} = useApolloQuery({
    query: gql`
      query userPreferencesNavbar($websiteId: ID!) {
        userPreferences(websiteId: $websiteId) {
          _id
          store {
            _id
            name
          }
          tableName
        }
      }
    `,
    partial: true,
    variables: {
      websiteId
    },
    omit: !websiteId || isServerSide()
  })

  const {website} = useInitialData()
  const isAdmin = useHasUserRoleInWebsite('admin')
  const navbar = (website && website.navbarConfiguration) || {
    backgroundColor: '#fff',
    textColor: '#111',
    logo: null,
    buttonColor: null,
    links: null
  }
  const finalLogo: websiteInitialData_cached_website_logo = navbar.logo ?? website.logo
  const [onTable] = useSessionStorageState('onTable')

  const renderLogo = (logoProps?) =>
    finalLogo ? <Logo config={navbar} website={website} {...logoProps} /> : null

  const renderMenu = () => {
    return (
      // @ts-expect-error ts-migrate(2740) FIXME: Type '{ pointsConfiguration: websiteInitialData_we... Remove this comment to see the full error message
      <Menu
        pointsConfiguration={website.pointsConfiguration}
        buttonColor={navbar.buttonColor ?? '#252525'}
      />
    )
  }

  const renderLinks = () => {
    return <Links links={navbar.links} categories={website.categories} />
  }

  return (
    <>
      {isAdmin ? (
        <ClientSuspense fallback={<LoadingText />}>
          <AdminBar pages={website.pages} />
        </ClientSuspense>
      ) : null}
      <div
        className={`${styles.container} ${onTable ? styles.containerTableOrder : null}`}
        style={{backgroundColor: navbar.backgroundColor, color: navbar.textColor}}>
        {onTable && userPreferences?.store ? (
          <TableInfo
            store={userPreferences.store.name}
            table={userPreferences.tableName}
            Logo={renderLogo}
            color={navbar.textColor}
          />
        ) : (
          <>
            <SafeSuspense fallback={<LoadingText />}>{renderMenu()}</SafeSuspense>
            {renderLogo()}
            {renderLinks()}
          </>
        )}
      </div>
    </>
  )
}
