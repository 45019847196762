import React, {useEffect, useState} from 'react'
import {MdEdit as Edit, MdExitToApp as Exit} from 'react-icons/md'
import Image from '@components/Image'
import getAdminDashboardUrl from '@helpers/websites/getAdminDashboardUrl'
import useLogo from '@hooks/useLogo'
import useWebsiteId from '@hooks/useWebsiteId'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function AdminBar(props) {
  const {pages} = props
  const {t} = useTranslation('website', {keyPrefix: 'layout.layouts.default.navbar.adminBar'})
  const websiteId = useWebsiteId()
  const router = useRouter()
  const [currentPage, setCurrentPage] = useState(null)
  const [currentProduct, setCurrentProduct] = useState(null)
  const logoUrl = useLogo({darkMode: false})

  useEffect(() => {
    const pathname = router.asPath
    const current = pages.find(page => {
      return page.path === pathname
    })

    if (current) {
      setCurrentPage(current._id)
      setCurrentProduct(null)
    } else {
      setCurrentPage(null)
      if (pathname.includes('pedir')) {
        const path = pathname.split('/')
        if (path.length === 4 && path[2] !== 'category') {
          setCurrentProduct(path[2])
        }
      }
    }
  }, [router.pathname, pages])

  return (
    <div className={`${styles.container} ${currentProduct ? styles.fixedContainer : null}`}>
      <div className={styles.logoJusto}>
        <Image width="55" height="16" src={logoUrl} alt="Logo de Justo" />
      </div>
      <div className={styles.actions}>
        {currentPage ? (
          <Link href={getAdminDashboardUrl(websiteId, `/pages/${currentPage}/components`)} passHref>
            <a target="_blank" className={styles.edit}>
              <Edit /> {t('labelEditPage')}
            </a>
          </Link>
        ) : null}
        {currentProduct ? (
          <Link
            href={getAdminDashboardUrl(websiteId, `/products/${currentProduct}/information`)}
            passHref>
            <a target="_blank" className={styles.edit}>
              <Edit /> {t('labelEditProduct')}
            </a>
          </Link>
        ) : null}
        <Link href={getAdminDashboardUrl(websiteId, '')} passHref>
          <a target="_blank" className={styles.admin}>
            <Exit /> {t('labelGoToAdmin')}
          </a>
        </Link>
      </div>
    </div>
  )
}
